import React, { useState } from 'react'
import { Modal } from '@siftscience/focus-components/modal'
import { Title } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { Eye, Plus } from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'
import { TemplateDTO } from '../dto/template'

const MODAL_ACTIONS_TITLES = {
  save: 'Save',
  cancel: 'Cancel'
}

const useStyles = makeStyles(() => ({
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    padding: '8px 16px',
    borderTop: '1px solid #E0E0E0'
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row'
  },
  fixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 300
  }
}))

interface CustomRuleSetRowProps {
  readonly?: boolean
  template?: TemplateDTO
}

const CustomRuleSetRow = ({ readonly, template }: CustomRuleSetRowProps) => {
  const [modalState, setModalState] = useState({ visible: false })
  const classes = useStyles()
  const rules = template?.evidenceRules?.[0]?.json_logic_ruleset
  const areRulesEmpty = Object.keys(rules || {})?.length === 0

  const onOpenModal = () => {
    setModalState({ visible: true })
  }

  const onCloseModal = () => {
    setModalState({ visible: false })
  }

  const onAction = (action: { label }) => {
    if (action.label === MODAL_ACTIONS_TITLES.save) {
      // TODO add logic for saving rules
      // onSaveRules()
    }
    onCloseModal()
  }

  return (
    <div className={classes.actionBlock}>
      <Title size="xsmall">Custom Rule Set</Title>
      {readonly && !areRulesEmpty && (
        <Button
          variant="secondary"
          className={classes.actionButton}
          lined
          onClick={onOpenModal}
        >
          <Eye />
          <Title size="xsmall">Show Rule Set</Title>
        </Button>
      )}
      {!readonly && areRulesEmpty && (
        <Button
          variant="secondary"
          className={classes.actionButton}
          lined
          onClick={onOpenModal}
        >
          <Plus />
          <Title size="xsmall">Add Rule Set</Title>
        </Button>
      )}
      {!readonly && !areRulesEmpty && (
        <Button
          variant="secondary"
          className={classes.actionButton}
          lined
          onClick={onOpenModal}
        >
          <Plus />
          <Title size="xsmall">Edit Rule Set</Title>
        </Button>
      )}
      {modalState?.visible ? (
        <div className={classes.fixed}>
          <Modal
            actions={[
              {
                label: MODAL_ACTIONS_TITLES.save,
                variant: 'primary'
              },
              {
                label: MODAL_ACTIONS_TITLES.cancel,
                lined: true,
                variant: 'secondary'
              }
            ]}
            closeable
            onAction={onAction}
            onClickOutside={onCloseModal}
            onCloseButtonClick={onCloseModal}
            size="large"
            title="Custom Rule Set"
            variant="fixed"
            visible
          />
        </div>
      ) : null}
    </div>
  )
}

export default CustomRuleSetRow
