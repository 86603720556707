/* eslint-disable camelcase */

export enum TemplateType {
  responseTemplate = 'response_template',
  stylesheet = 'stylesheet',
  configurationPanel = 'configuration_panel'
}

export enum ProcessorFieldType {
  STRING = 'string',
  IMAGE = 'image',
  PDF = 'pdf'
}

export enum DataFieldType {
  TEXT = 'text',
  DATE = 'date',
  DATETIME = 'date_time',
  IMAGE = 'image',
  BOOLEAN = 'boolean',
  ARRAY = 'array'
}

export interface JSONSchema {
  items: {
    properties: { [key: string]: { name: string; type: string[] } }
    required?: string[]
  }[]
}

export interface ProcessorField {
  id: string | number
  created_at: string
  updated_at: string
  field_name: string
  portal_id: number
  data_type: ProcessorFieldType
}

export interface DataField {
  created_at?: string
  updated_at?: string
  id?: number
  name: string
  rank?: number
  data_type: DataFieldType
  schema?: JSONSchema
  required: boolean
  dataset_path: string
  templateId?: number
  default_value: string
  det_template_id?: number
}

export interface EvidenceRule {
  id?: number
  det_template_id?: number
  reason_code_categories?: string[]
  json_logic_ruleset?: Record<string, unknown>
}

export interface TemplateDTO {
  evidenceRules: EvidenceRule[]
  templateId: number
  content: string
  displayName: string
  merchantId: number
  name: string
  templateType: TemplateType
  childTemplateIds: number[]
  overridenByMerchants: string[]
  parentTemplateIds: number[]
  processorField: ProcessorField
  dataFields: DataField[]
  portal: unknown
  merchantSpecific: boolean
}
