import React, { HTMLAttributes, useEffect, useState } from 'react'
import { Select } from '@siftscience/focus-components/select'
import { makeStyles } from '@material-ui/core/styles'
import { renderSearchFieldHeader } from '@siftscience/focus-components/dropdown'

const useStyles = makeStyles(() => ({
  actionBarWrapper: {
    '& span:empty': {
      // Workaround for an existing bug in lib
      display: 'none'
    }
  },
  footerWrapper: {
    flexDirection: 'row',
    boxShadow: '0 0 5px 0 rgba(36, 36, 36, .16)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 16px'
  }
}))

export interface DropdownItem {
  name?: string
  id: number | string
}

interface TemplateToolingHeaderDropdownProps {
  items: DropdownItem[]
  onSelect: (item: DropdownItem) => void
  renderItem: (item: DropdownItem) => React.ReactElement
  onOpenChange: () => void
  renderTrigger: (props: HTMLAttributes<HTMLElement>) => React.ReactElement
  footerContent: React.ReactElement
}

const TemplateToolingSelect = ({
  items,
  onSelect,
  renderTrigger,
  renderItem,
  footerContent,
  onOpenChange
}: TemplateToolingHeaderDropdownProps): React.ReactElement => {
  const [displayItems, setDisplayItems] = useState(items)
  const [searchFilter, setSearchFilter] = useState('')
  const classes = useStyles()

  const onFilter = event => {
    const value = event?.target?.value
    setSearchFilter(value)
  }

  const onIsOpenChange = () => {
    setSearchFilter('')
    onOpenChange()
  }

  useEffect(() => {
    if (!items) {
      return
    }
    const filteredItems = items.filter(
      item =>
        `${item.id}`?.toLowerCase()?.includes(searchFilter?.toLowerCase()) ||
        item?.name?.toLowerCase()?.includes(searchFilter?.toLowerCase())
    )
    setDisplayItems(filteredItems)
  }, [items, searchFilter])

  return (
    <Select
      displayRenderer={({ getToggleButtonProps }) =>
        renderTrigger(getToggleButtonProps())
      }
      items={displayItems}
      getItemLabel={(item: DropdownItem) => renderItem(item)}
      selectedItem={null} // To keep selected item "inactive" on UI side
      onChange={onSelect}
      variant="secondary"
      placement="top"
      strategy="fixed"
      headerRenderer={renderSearchFieldHeader({ onChange: onFilter })}
      footerRenderer={() => (
        <div className={classes.footerWrapper}>{footerContent}</div>
      )}
      onIsOpenChange={onIsOpenChange}
      stretch={false}
    />
  )
}

export default TemplateToolingSelect
