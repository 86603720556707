import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { Title } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { Switch } from '@siftscience/focus-components/switch'
import { Override, Rollback } from '@siftscience/focus-components/icons'
import { startCase } from 'lodash'
import AddRow from './add-row'
import ReasonCodesRow from './reason-codes-row'
import CustomRuleSetRow from './custom-rule-set-row'
import MerchantsAffectedRow from './merchants-affected-row'
import { TemplateDTO } from '../dto/template'
import { useTemplateContext } from '../context/template-context'
import { useDataFieldContext } from '../context/data-field-context'
import { useCurrentUser } from '../../../contexts/CurrentUserContext'

interface StyleProps {
  isFullWidth: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    gap: '8px',
    height: '100%',
    maxWidth: props => (props.isFullWidth ? '100%' : 'calc(50% - 8px)') // 8px for half the gap
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px'
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px'
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    height: '100%',
    border: '1px solid #E0E0E0',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF'
  },
  contentArea: {
    paddingLeft: '12px',
    paddingTop: '6px'
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row'
  },
  subtemplate: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    backgroundColor: '#F7F7F7',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    padding: '10px 16px',
    cursor: 'pointer',
    margin: '5px 0',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400
  },
  datafield: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    backgroundColor: '#FFF6D6',
    border: '1px solid #F2B200',
    borderRadius: '4px',
    padding: '2px',
    cursor: 'pointer',
    margin: '5px 0',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400
  }
}))

interface TemplateEditorProps {
  title: string
  readonly?: boolean
  isFullWidth?: boolean
  template: TemplateDTO
}

const SUBTEMPLATE_PATTERN = /{{\s*st\s+'([^']*)'\s*}}/g
const DATAFIELD_PATTERN = /{{\s*df\s+'(\$\.[\w.]*)'\s*}}/g

const TemplateEditor = ({
  title,
  readonly,
  template,
  isFullWidth
}: TemplateEditorProps) => {
  const { templatesHash, activeTemplateData, removeTemplate } =
    useTemplateContext()
  const { dataFieldsHash } = useDataFieldContext()
  const { currentUser } = useCurrentUser()
  const contentEditableRef = useRef(null)
  const [content, setContent] = useState('')

  const classes = useStyles({ isFullWidth })

  const handleContentChange = (defaultContent?: string) => {
    let htmlContent = defaultContent || contentEditableRef.current.innerHTML

    if (!htmlContent) {
      return
    }

    htmlContent = htmlContent.replace(SUBTEMPLATE_PATTERN, (match, p1) => {
      // TODO add NewTab icon
      return `
        <div class=${
          classes.subtemplate
        } data-initialValue='${match}' contenteditable="false">
          ${startCase(p1)}
        </div>
      `
    })

    htmlContent = htmlContent.replace(DATAFIELD_PATTERN, (match, p1) => {
      // TODO add Aa icon
      return `
        <span class=${
          classes.datafield
        } data-initialValue='${match}' contenteditable="false">
          ${startCase(p1)}
        </span>
      `
    })

    setContent(htmlContent)
    contentEditableRef.current.innerHTML = htmlContent
  }

  useEffect(() => {
    if (template?.content) {
      handleContentChange(template?.content)
    }
  }, [template])

  useEffect(() => {
    handleContentChange()
  }, [content])

  const onAddSubtemplate = (subtemplateId: number) => {
    const template = templatesHash.get(subtemplateId)
    const newContent = content + `{{st '${template.name}'}}`
    setContent(newContent)
    // TODO get last caret position to know where to insert subtemplate
  }

  const onAddDataField = (dataFieldId: number) => {
    const dataField = dataFieldsHash.get(dataFieldId)
    const newContent = content + `{{df '${dataField.dataset_path}'}}`
    setContent(newContent)
    // TODO get last caret position to know where to insert subtemplate
  }

  const onDeleteTemplate = () => {
    removeTemplate(activeTemplateData.templateId)
  }

  const isGPDEditAllowed = currentUser.hasAccess(['Super Administrator'])
  const isDeleteAllowed =
    activeTemplateData?.overridenByMerchants?.length === 0 &&
    !activeTemplateData.processorField

  const isOverridenTemplate = !!activeTemplateData?.merchantId

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Title size="medium">{title}</Title>
          {!readonly && <Switch />}
        </div>
        <div className={classes.headerRight}>
          {!readonly && isOverridenTemplate && (
            <Button variant="secondary" className={classes.actionButton} lined>
              <Rollback />
              <Title size="xsmall">Revert to Default</Title>
            </Button>
          )}
          {!readonly && !isOverridenTemplate && (
            // TODO Do not show if GPD already has override
            <Button variant="primary" className={classes.actionButton} lined>
              <Override />
              <Title size="xsmall">Override Template</Title>
            </Button>
          )}
          {isGPDEditAllowed && (
            <Button
              variant="danger"
              disabled={!isDeleteAllowed}
              className={classes.actionButton}
              onClick={onDeleteTemplate}
            >
              <Title size="xsmall">Delete</Title>
            </Button>
          )}
        </div>
      </div>
      <div className={classes.innerWrapper}>
        <div
          ref={contentEditableRef}
          className={classes.contentArea}
          dangerouslySetInnerHTML={{ __html: content }}
          onInput={() => handleContentChange()}
          contentEditable={!readonly}
          suppressContentEditableWarning={!readonly}
        />
        <div>
          {isGPDEditAllowed && <MerchantsAffectedRow />}
          {!readonly && (
            <AddRow
              onAddSubtemplate={onAddSubtemplate}
              onAddDataField={onAddDataField}
            />
          )}
          <ReasonCodesRow readonly={readonly} />
          <CustomRuleSetRow readonly={readonly} template={activeTemplateData} />
        </div>
      </div>
    </div>
  )
}

export default TemplateEditor
