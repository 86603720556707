import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'
import { DataField, DataFieldType } from '../dto/template'

const DATA_FIELDS_MOCK: DataField[] = [
  {
    id: 38,
    name: 'evidence',
    data_type: DataFieldType.ARRAY,
    required: false,
    dataset_path: '$.evidence',
    det_template_id: 9,
    created_at: '2024-10-22T14:12:47.099Z',
    updated_at: '2024-10-22T14:12:47.099Z',
    schema: null,
    default_value: null,
    rank: 0
  },
  {
    id: 39,
    name: 'invoice.line_items',
    data_type: DataFieldType.ARRAY,
    required: false,
    dataset_path: '$.invoice.line_items',
    det_template_id: 9,
    created_at: '2024-10-22T14:12:47.100Z',
    updated_at: '2024-10-22T14:12:47.100Z',
    schema: null,
    default_value: null,
    rank: 0
  },
  {
    id: 40,
    name: 'evidence',
    data_type: DataFieldType.ARRAY,
    required: false,
    dataset_path: '$.evidence',
    det_template_id: 10,
    created_at: '2024-10-22T14:12:47.108Z',
    updated_at: '2024-10-22T14:12:47.108Z',
    schema: null,
    default_value: null,
    rank: 0
  },
  {
    id: 32,
    name: 'destination_address1',
    data_type: DataFieldType.TEXT,
    required: false,
    dataset_path: '$.shipping_info.destination_address1',
    det_template_id: 8,
    created_at: '2024-10-22T14:12:47.085Z',
    updated_at: '2024-10-22T14:12:47.085Z',
    schema: null,
    default_value: null,
    rank: 0
  },
  {
    id: 33,
    name: 'destination_city',
    data_type: DataFieldType.TEXT,
    required: false,
    dataset_path: '$.shipping_info.destination_city',
    det_template_id: 8,
    created_at: '2024-10-22T14:12:47.086Z',
    updated_at: '2024-10-22T14:12:47.086Z',
    schema: null,
    default_value: null,
    rank: 0
  },
  {
    id: 34,
    name: 'destination_state',
    data_type: DataFieldType.TEXT,
    required: false,
    dataset_path: '$.shipping_info.destination_state',
    det_template_id: 8,
    created_at: '2024-10-22T14:12:47.087Z',
    updated_at: '2024-10-22T14:12:47.087Z',
    schema: null,
    default_value: null,
    rank: 0
  },
  {
    id: 35,
    name: 'destination_postal',
    data_type: DataFieldType.TEXT,
    required: false,
    dataset_path: '$.shipping_info.destination_postal',
    det_template_id: 8,
    created_at: '2024-10-22T14:12:47.088Z',
    updated_at: '2024-10-22T14:12:47.088Z',
    schema: null,
    default_value: null,
    rank: 0
  },
  {
    id: 36,
    name: 'destination_country',
    data_type: DataFieldType.TEXT,
    required: false,
    dataset_path: '$.shipping_info.destination_country',
    det_template_id: 8,
    created_at: '2024-10-22T14:12:47.089Z',
    updated_at: '2024-10-22T14:12:47.089Z',
    schema: null,
    default_value: null,
    rank: 0
  },
  {
    id: 37,
    name: 'shipments',
    data_type: DataFieldType.ARRAY,
    required: false,
    dataset_path: '$.shipments',
    det_template_id: 8,
    created_at: '2024-10-22T14:12:47.091Z',
    updated_at: '2024-10-22T14:12:47.091Z',
    schema: null,
    default_value: null,
    rank: 0
  }
]

export const useDataFields = () => {
  const {
    data: dataFieldsData,
    isLoading: isDataFieldsLoading,
    refetch: refetchDataFields
  } = useQuery(
    `data-fields-list`,
    () => axios.get<DataField[]>('/det/tooling/data_fields'),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  return {
    dataFieldsData: (dataFieldsData?.data as DataField[]) || DATA_FIELDS_MOCK, // TODO remove mock
    isDataFieldsLoading,
    refetchDataFields
  }
}
