import React from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import TemplateToolingHeader from './header'
import MainView from './main-view'
import Navigation from './navigation-components/navigation'
import capitalize from '../../utils/capitalize'
import { TemplateToolingProvider } from './context'

const useStyles = makeStyles(() => ({
  mainArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}))

const TemplateTooling = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <>
      <Helmet>
        <title>Sift | {capitalize('Template Tooling')}</title>
      </Helmet>
      <div>
        <TemplateToolingHeader />
        <div className={classes.mainArea}>
          <MainView />
          <Navigation />
        </div>
      </div>
    </>
  )
}

const TemplateToolingWithProviders = () => {
  return (
    <TemplateToolingProvider>
      <TemplateTooling />
    </TemplateToolingProvider>
  )
}

export default TemplateToolingWithProviders
